@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700;800&display=swap');
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

* {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;    
}

html,
body {
  padding: 0;
  margin: 0;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  color: #141414;
  background-color: #ffffff;  
}

.modal-open {
  padding-right: 0 !important;
}

body:before {
  overflow: auto !important;
}

html {
  overflow-y: scroll;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

/* calendar start */
/* by setting font-size, all the elements will correspond */

.responsive-calendar {
  font-size: 10px !important;
  box-shadow: none !important;
  width: 100% !important;
  max-width: 592px !important;
  min-height: 0 !important;
  padding: 0.6em 0 !important;
}

/* .responsive-calendar .Calendar__sectionWrapper {
  min-height: 22.5em !important;
} */

.responsive-calendar .Calendar__section {
  padding: 0 2em !important;
}

.responsive-calendar .Calendar__section.-hiddenPrevious,
.responsive-calendar .Calendar__section.-hiddenNext {
  opacity: 0 !important;
}

.responsive-calendar .Calendar__weekDays {
  padding: 0 1.7em !important;
}

.responsive-calendar abbr {
  color: #374553 !important;
  text-decoration: none !important;
  cursor: auto;
}

.responsive-calendar .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  width: 100%;
  max-width: 1.2em;
  height: 2px;
  opacity: 1;
  left: calc(50% + 0.05em);
  background-color: #00a7e1;
}

.responsive-calendar .Calendar__monthYear {
  flex-direction: row-reverse;
  justify-content: center;
  width: 100%;
}

.responsive-calendar .Calendar__monthText {
  margin: 0 0 0 1em !important;
}

.responsive-calendar .Calendar__monthText,
.responsive-calendar .Calendar__yearText {
  pointer-events: none !important;
  padding: 0 !important;
}

.responsive-calendar .Calendar__yearText::after {
  content: '년';
}

.responsive-calendar .Calendar__day {
  transform: scale(1);
  transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
}

.responsive-calendar .Calendar__day:hover {
  border-radius: 24px !important;
  transform: scale(1.1);
  transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
}

.responsive-calendar .Calendar__day.dayoff:hover {
  border-radius: 24px !important;
  transform: scale(1.1);
  transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
}

.responsive-calendar .Calendar__day:focus {
  outline: none !important;
}

.responsive-calendar .Calendar__day.-selected {
  font-weight: bold;
  color: #fefefe !important;
  border-radius: 24px !important;
}

.holiday {
  color: #ff2929 !important;
}

.dayoff {
  color: #c0c1c2 !important;
}

.dayoff::after {
  content: '휴장';
  position: absolute;
  font-size: 0.75em;
  bottom: -6px;
}


.dayoff.-selected {
  background-color: #d0d1d2 !important;
}

.dayoff.-selected:hover {
  color: #fff !important;
  background-color: #d0d1d2 !important;
}

.dayoff:hover {
  color: #c0c1c2 !important;
  background-color: #ffffff00 !important;
}

.dayoff.-selected::after {
  content: '';
}

/* calendar end */

/* media start */

.pc-wrapper {
  color: #fff;
  display: none;
}

@media (orientation: landscape) {
  /* Landscape 모드일 때 적용할 CSS */
}

@media only screen and (min-width: 1400px) {
  body:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/smob-desktop-wallpaper.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }
  #__next {
    left: calc(50% - 45px);
    box-shadow: 0 0 10px 5px #00000011;
    margin: 0;
  }
  .pc-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 160px;
    left: max(calc(50% - 33vw), calc(50% - 720px));
    height: calc(100% - 250px);
    z-index: 10;
  }
  .pc-branch-bi {
    cursor: pointer;
  }
  .pc-promo {
    margin: 80px 0 0 0;
    -webkit-animation: 0.5s ease-out 0.2s both smoothAppearUpY;
            animation: 0.5s ease-out 0.2s both smoothAppearUpY;
  }
  .pc-branch-info {
    margin: 120px 0 0 0;
    color: rgba(255, 255, 255, 0.4);
    font-weight: 300;
    font-size: 12px;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1399px) {
  body:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/smob-desktop-wallpaper.jpg');
    background-position: 25% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }
  #__next {
    margin: 0 auto;
    box-shadow: 0 0 10px 5px #00000011 !important;
  }
}

@media only screen and (max-width: 639px) {
  body:before {
    content: normal;
    content: initial;
  }
  #__next {
    margin: 0 auto;
  }
}

/* media end */

.nav-fill .nav-item,
.nav-fill > .nav-link {
  color: #c5c8ce;
  flex: 1 1 !important;
}

.modal-content {
  border: none !important;
}

.terms-title {
  margin: 0 0 1em 0;
}

/* Modal */
.modal-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 20px 20px;
}

.modal-menu {
  display: flex;
  gap: 16px;
}

.modal-menu button {
  width: 100%;
}

.modal-content-title {
  font-family: NEXON Lv1 Gothic;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 1.2em;
}

.modal-content-text {
  font-family: NEXON Lv1 Gothic;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  line-height: 1.2em;
  margin-top: -16px;
}

/* for Smob start */
.tag-primary {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0 6px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 20px;
  background: #141414;
  border-radius: 2px;
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  font-weight: 500;
  color: #ffffff;
  gap: 4px;
}

.tag-blue {
  display: inline-flex;  
  flex-shrink: 0;
  align-items: center;
  padding: 0 6px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 20px;
  background: #0078FF;
  border-radius: 2px;
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  font-weight: 500;
  color: #ffffff;
  gap: 4px;
}

.tag-red {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0 6px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 20px;
  background: #E8334F;
  border-radius: 2px;
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  font-weight: 500;
  color: #ffffff;
  gap: 4px;
}

.tag-disabled {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0 6px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 20px;
  background: #B1B1B1;
  border-radius: 2px;
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  font-weight: 500;
  color: #ffffff;
  gap: 4px;
}

.tag-white-50 {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0 6px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  font-weight: 500;
  color: #141414;
  gap: 4px;
}

.tag-white-75 {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0 6px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 20px;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 2px;
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  font-weight: 500;
  color: #141414;
  gap: 4px;
}

.tag-black-50 {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0 6px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  font-weight: 500;
  color: #ffffff;
  gap: 4px;
}

.weight-400 {
  font-weight: 400;
}

.weight-700 {
  font-weight: 700;
}

.weight-800 {
  font-weight: 800;
}

.Mui-disabled {
  background-color: #ebebeb !important;
  color: #c1c1c1 !important;
  opacity: 1 !important;
}

.MuiTab-root {
  color:rgba(0, 0, 0, 0.4);
}

.MuiTabs-indicator {
  height: 3px;
  color: #141414;
}

.MuiDialog-paper {
  border-radius: 0;
  margin: 0 24px;
  width: calc(100% - 48px);
}

.swiper-slide-active .cover {
  box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.3);
  line-height: 0;
}


/* for Smob end */

@-webkit-keyframes fireAnimation {
  25% {
    background-position: top 0 left -210px;
  }
  50% {
    background-position: top -350px left -210px;
  }
  75% {
    background-position: top -350px left 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes fireAnimation {
  25% {
    background-position: top 0 left -210px;
  }
  50% {
    background-position: top -350px left -210px;
  }
  75% {
    background-position: top -350px left 0;
  }
  100% {
    background-position: 0 0;
  }
}


/* common ui animations */

@-webkit-keyframes smoothAppearY {
  from {
    opacity: 0;
    transform: translateY(-5%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes smoothAppearY {
  from {
    opacity: 0;
    transform: translateY(-5%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes smoothAppearUpY {
  from {
    opacity: 0;
    transform: translateY(5%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes smoothAppearUpY {
  from {
    opacity: 0;
    transform: translateY(5%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes smoothAppearX {
  from {
    opacity: 0;
    transform: translateX(-5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes smoothAppearX {
  from {
    opacity: 0;
    transform: translateX(-5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes smoothAppearRightX {
  from {
    opacity: 0;
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes smoothAppearRightX {
  from {
    opacity: 0;
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes smoothAppearReverseXFull {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes smoothAppearReverseXFull {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes appearY {
  from {
    transform: translateY(-5%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes appearY {
  from {
    transform: translateY(-5%);
  }
  to {
    transform: translateY(0);
  }
}

@-webkit-keyframes slideUpY {
  from {
    transform: translateY(20%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideUpY {
  from {
    transform: translateY(20%);
  }
  to {
    transform: translateY(0);
  }
}

@-webkit-keyframes appearX {
  from {
    transform: translateX(-5%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes appearX {
  from {
    transform: translateX(-5%);
  }
  to {
    transform: translateX(0);
  }
}

@-webkit-keyframes slideX {
  from {
    transform: translateX(-20%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slideX {
  from {
    transform: translateX(-20%);
  }
  to {
    transform: translateX(0%);
  }
}

@-webkit-keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes click {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.9);
  }
  75%,
  100% {
    transform: scale(1);
  }
}

@keyframes click {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.9);
  }
  75%,
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes tick {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes tick {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}


@-webkit-keyframes bounce {
  0% {
    transform: scaleY(0);
    transform-origin: bottom;
    opacity: 0;
  }
  33% {
    transform: scaleY(0.33);
    transform-origin: bottom;
    opacity: 0.75;
  }
  66% {
    transform: scaleY(0.66);
    transform-origin: bottom;
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    transform-origin: bottom;
  }
}


@keyframes bounce {
  0% {
    transform: scaleY(0);
    transform-origin: bottom;
    opacity: 0;
  }
  33% {
    transform: scaleY(0.33);
    transform-origin: bottom;
    opacity: 0.75;
  }
  66% {
    transform: scaleY(0.66);
    transform-origin: bottom;
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    transform-origin: bottom;
  }
}

@-webkit-keyframes bottomDrawer {
  from {
    transform: translate(0, 100%);
    opacity: 0;
  }
  to {
    transform: translate(0%, 0);
    opacity: 1;
  }
}

@keyframes bottomDrawer {
  from {
    transform: translate(0, 100%);
    opacity: 0;
  }
  to {
    transform: translate(0%, 0);
    opacity: 1;
  }
}

.next_image {
  height: inherit !important;
  min-height: 0 !important;
}

abbr[data-original-title],
abbr[title] {
  cursor: default !important;
}

